import './App.css';
import './utils.js';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ethers } from 'ethers'
import { DeFiWeb3Connector } from "deficonnect";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { providers } from "ethers"


import DAI from './artifacts/contracts/mocks/DAI.sol/CronosCRC20.json'
import BOCNFTLaunch from './artifacts/contracts/BOCLaunchNft.sol/BOC_NFT_Basic.json'
import BOCNFTLaunchPremier from './artifacts/contracts/BOCLaunchNftPremier.sol/BOC_NFT_Premier.json'
import BOCNFTLaunchExclusive from './artifacts/contracts/BOCLaunchNftExclusive.sol/BOC_NFT_Exclusive.json'
import BOCMultisig from './artifacts/contracts/MultiSigWallet.sol/MultiSigWallet.json';

import OHM from './artifacts/contracts/OlympusERC20.sol/OlympusERC20Token.json'
import Frax from './artifacts/contracts/mocks/Frax.sol/FRAX.json'
import Treasury from './artifacts/contracts/Treasury.sol/OlympusTreasury.json'
import OlympusBondingCalculator from './artifacts/contracts/StandardBondingCalculator.sol/OlympusBondingCalculator.json'
import Distributor from './artifacts/contracts/StakingDistributor.sol/Distributor.json'
import SOHM from './artifacts/contracts/sOlympusERC20.sol/sOlympus.json'
import Staking from './artifacts/contracts/Staking.sol/OlympusStaking.json'
import StakingWarmpup from './artifacts/contracts/StakingWarmup.sol/StakingWarmup.json'
import StakingHelper from './artifacts/contracts/StakingHelper.sol/StakingHelper.json'
import DAIBond from './artifacts/contracts/mocks/MockBondDepository.sol/MockOlympusBondDepository.json'
import FraxBond from './artifacts/contracts/mocks/MockBondDepository.sol/MockOlympusBondDepository.json'
import CronaswapFactory from './artifacts/contracts/CronaSwapFactory.sol/CronaSwapFactory.json'
import CronaswapRouter from './artifacts/contracts/CronaSwapRouter.sol/CronaSwapRouter.json'


function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}




// PROD ADDRESSES
// const DAIAddress = '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59'
// const BOCNFTAddress = '0xff742cc0361e6e88c8e0a604a4e51fcda73c5bcc'
// const BOCNFTLaunchPremierAddress = '0x13808257d469CD5457882508880ba3aDF92A7975'
// const BOCNFTLaunchExclusiveAddress = '0x25238D67aB2cBdDCf0B04499285c0dD439c7b3ca'
// const BOCMultisigAddress = '0x1087234fe877721F30016ebeD5BEd061397C8851'



const BOCMultisigAddress = '0x1087234fe877721F30016ebeD5BEd061397C8851'
const BOCAddress = '0xe5786DDFc4D6DcA0973D1c5b02987cBbac66ed87'
const DAIAddress = '0xc21223249ca28397b4b6541dffaecc539bff0c59'
const FraxAddress = '0xF2001B145b43032AAF5Ee2884e456CCd805F677D'

const BOCNFTAddress = '0x256e4532d42542Fe36B6F1d1439e91F31d9c70BE'
const BOCNFTLaunchPremierAddress = '0x256e4532d42542Fe36B6F1d1439e91F31d9c70BE'
const BOCNFTLaunchExclusiveAddress = '0x256e4532d42542Fe36B6F1d1439e91F31d9c70BE'
//const FraxAddress = '0x867B46ea2dCFaf77aC8815D64A91BB4a645210ef'
const TreasuryAddress = '0x2E2f69acbfb6dDFCeA637424Ee96a0acD5eDC2ba'
const OlympusBondingCalculatorAddress = '0x4899FB567875536e14967E4aC147Fe04976098A0'
const DistributorAddress = '0x6392e507AB4d4d7501B0226A483432199C52aaC8'
const SBOCAddress = '0xBA6CDb55296281FC931BABdf1cF383Dce87Ea110'
const StakingAddress = '0x52A74D075c19B2eDF284650e9be2Aa4A60b0b712'
const StakingWarmpupAddress = '0x646283A39796b79cF7B12Ceb7442D9001EC5E1e0'
const StakingHelperAddress = '0x3BaD754ae47BBE5aaa2Cc1920E62d81692679059'
const DAIBondAddress = '0x46201dD00B1f5A01641d0c4302689D195FF3064a'
const FraxBondAddress = '0x2Cd04805Ac676a57CE1DaEE3D3a98cEA7122B7bD'
const ExercisePOLYBondAddress = ''
const pBOCBondAddress = ''

const CronaswapFactoryAddress = '0x004615D9cCab58bDE10877BE03F053c94599F3ce'
const CronaswapRouterAddress = '0x989dBb40f0B8a431e9D79dfd28fdC3df3717D9c0'


function App() {
    const [address, setaddress] = useState('Not Connected')
    const [txnum, settxnum] = useState(0)
    const [totaltx, settotaltx] = useState(0)
    const [txconfirms, settxconfirms] = useState(0)
    const [txconfirmrequired, settxconfirmrequired] = useState(0)
    const [daibalance, setdaibalance] = useState(0.00)
    const [ohmbalance, setohmbalance] = useState(0.00)
    const [sohmbalance, setsohmbalance] = useState(0.00)
    const [connecterror, setconnecterror] = useState('')
    const [mintsuccess, setmintsuccess] = useState('')
    const [nftbasicremaining, setnftbasicremaining] = useState('?')
    const [nftpremierremaining, setnftpremierremaining] = useState('?')
    const [nftexclusiveremaining, setnftexclusiveremaining] = useState('?')
    const [optionsState, setoptionsState] = useState(1)
    const [connection, setconnection] = useState(0)


  //   useEffect(() => {
  //   // Update the document title using the browser API
  //   document.title = `You clicked TEST times`;
  //   try {
  //       GetBalances_BOC_WC()
  //   } catch (e){
  //       console.log("Defi-Wallet-Failed");
  //   }
  // });


    async function connectapp(provider, connection_num){
            console.log(provider);
            const signer = provider.getSigner()
            const account = await provider.getSigner().getAddress()
            console.log(account);
            const chainId = await provider.getNetwork();
            console.log(chainId.chainId);
            if(chainId.chainId!='25'){
                setconnecterror('Bank of Cronos is not supported on this network. Please connect to Cronos Mainnet');
                return;
            }

            // const BOCNFT = new ethers.Contract(BOCNFTAddress, BOCNFTLaunch.abi, signer);
            // console.log(await BOCNFT.txFeeAmount());
            // const BOCNFTPremier = new ethers.Contract(BOCNFTLaunchPremierAddress, BOCNFTLaunchPremier.abi, signer);
            // const BOCNFTExclusive = new ethers.Contract(BOCNFTLaunchExclusiveAddress, BOCNFTLaunchExclusive.abi, signer);





            // const ohm = new ethers.Contract(BOCAddress, OHM.abi, signer)
            const dai = new ethers.Contract(DAIAddress, DAI.abi, signer)
            const frax = new ethers.Contract(FraxAddress, Frax.abi, signer)
            // const treasury = new ethers.Contract(TreasuryAddress, Treasury.abi, signer)
            // const olympusBondingCalculator = new ethers.Contract(OlympusBondingCalculatorAddress, OlympusBondingCalculator.abi, signer)
            // const distributor = new ethers.Contract(DistributorAddress, Distributor.abi, signer)
            // const sOHM = new ethers.Contract(SBOCAddress, SOHM.abi, signer)
            // const staking = new ethers.Contract(StakingAddress, Staking.abi, signer)
            // const stakingWarmup = new ethers.Contract(StakingWarmpupAddress, StakingWarmpup.abi, signer)
            // const stakingHelper = new ethers.Contract(StakingHelperAddress, StakingHelper.abi, signer)
            // const daiBond = new ethers.Contract(DAIBondAddress, DAIBond.abi, signer)
            // const fraxBond = new ethers.Contract(FraxBondAddress, FraxBond.abi, signer)

            // await dai.approve(TreasuryAddress, 800000000 );
            // // // Deposit 9,000,000 DAI to treasury, 600,000 OHM gets minted to deployer and 8,400,000 are in treasury as excesss reserves
            //
            // await treasury.deposit('800000000', dai.address, '632000000000');
            // console.log("deposit 8k USDC in treasury, 1600 (IPNO) + 80 (Initial LP) BOC for deployer and 6320 in treasury as excess reserve");
            // sleep(10000);
            // // // Deposit 5,000,000 Frax to treasury, all is profit and goes as excess reserves
            // //     await treasury.deposit('5000000000000000000000000', frax.address, '5000000000000000');
            // //     console.log("deposit 5m FRAX in treasury, 0 BOC for deployer and 5m in treasury as excess reserve");
            // // sleep(10000);
            // // // Stake OHM through helper
            // await stakingHelper.stake('100000000000');
            // console.log("Staking...");
            // sleep(10000);
            // // // Bond 1,000 OHM and Frax in each of their bonds
            // await daiBond.deposit('1000000000000000000000', '60000', account );
            // sleep(10000);
            // await fraxBond.deposit('1000000000000000000000', '60000', account );
            // console.log("Deposit Frax and DAI bonds");



             // const CSFactory = new ethers.Contract(CronaswapFactoryAddress, CronaswapFactory.abi, signer);
             // const CSRouter = new ethers.Contract(CronaswapRouterAddress, CronaswapRouter.abi, signer);
             // const LPaddress = await CSFactory.createPair(ohm.address,dai.address);
             // console.log("Deploying Initial Liquidity Pool");
             // sleep(1000);
             // console.log( "LPaddress = " + LPaddress.toString()  );
            const multisig = new ethers.Contract(BOCMultisigAddress, BOCMultisig.abi, signer);
            //await multisig.confirmTransaction('0');
            const required = await multisig.required();
            const confirms = await multisig.getConfirmationCount(txnum);
            const totaltrnarr = await multisig.getTransactionCount(true,true);
            console.log("Multisig Transactions: " + totaltrnarr);
            //const totaltrn = ethers.BigNumber.from(totaltrnarr );

            console.log("Multisig confirmations: " + required);
            settxconfirmrequired(required.toString());
            settxconfirms(confirms.toString());
            settotaltx(totaltrnarr.toString());


            const daibalance = await dai.balanceOf(BOCMultisigAddress);
            console.log("Multisig USDC balance: " + daibalance/1000000)
            const daibalance2 = await dai.balanceOf(account);
            console.log("Acccount USDC balance: " + daibalance2/1000000)
            // const ohmbalance = await ohm.balanceOf(BOCMultisigAddress);
            // console.log("BOC balance: " + ohmbalance/1000000000)
            // const sohmbalance = await sOHM.balanceOf(BOCMultisigAddress);
            // console.log("sBOC balance: " + sohmbalance/1000000000)
            // const index = await staking.index();
            // console.log("Index is: " + index);
            // const bonus = await staking.totalBonus();
            // console.log("TotalBonus is: " + bonus);
            // const epoch = await staking.epoch();
            // console.log("epoch is: " + epoch);
            //await staking.rebase();



            //const allowance = await dai.allowance (signer.getAddress(),BOCNFTAddress );
            setdaibalance((daibalance/1000000));
            // setohmbalance((ohmbalance/1000000000));
            // setsohmbalance((sohmbalance/1000000000));
            setaddress(account);
            setconnecterror('');
            console.log(optionsState);
            // console.log(await BOCNFT.txFeeAmount());
            // const tokencount = await BOCNFT.tokenCounter();
            // console.log(tokencount.toNumber());
            // setnftbasicremaining(10000-tokencount.toNumber());
            // const tokencountp = await BOCNFTPremier.tokenCounter();
            // console.log(tokencountp.toNumber());
            // setnftpremierremaining(1000-tokencountp.toNumber());
            // const tokencounte = await BOCNFTExclusive.tokenCounter();
            // console.log(tokencounte.toNumber());
            // setnftexclusiveremaining(100-tokencounte.toNumber());

            setconnection(connection_num);

            if(optionsState == '1'){
                  const allowance = await dai.allowance(BOCMultisigAddress, BOCMultisigAddress);
                  console.log("allowance.toNumber(): ");
                  console.log(allowance.toNumber());
                  sleep(1000);
                  console.log("Approving BOCMultisigAddress")
                  //await dai.approve(BOCMultisigAddress, 100000000 );
                  sleep(10000);



                  // console.log("Approving Signer")
                  // await dai.approve(signer.getAddress(), 100000000 );
                    if(allowance.toNumber() < 100000000) {setconnecterror("NFT Minting Contract Not Approved - Please Approve to Mint")}
                    else{setconnecterror(" ")}
                    console.log(connecterror);
                    return
              }
              else if(optionsState == '2'){
                  try {
                      const allowance = await dai.allowance(signer.getAddress(), signer.getAddress());
                      console.log(allowance.toNumber());
                      if (allowance.toNumber() < 1000000000) {
                          setconnecterror("NFT Minting Contract Not Approved - Please Approve to Mint")
                      }
                      else{setconnecterror(" ")}
                      console.log(connecterror);
                      return
                  }
                  catch(e) {
                      setconnecterror("NFT Minting Contract Not Approved - Please Approve to Mint");
                      console.log(e);
                      return
                  }
              }
              else if(optionsState == '3'){
                  try {
                      const allowance = await dai.allowance(signer.getAddress(), signer.getAddress());
                      console.log(allowance.toNumber());
                      if (allowance.toNumber() < 10000000000) {
                          setconnecterror("NFT Minting Contract Not Approved - Please Approve to Mint")
                      }
                      else{setconnecterror(" ")}
                      console.log(connecterror);
                      return
                  }
                  catch(e) {
                      setconnecterror("NFT Minting Contract Not Approved - Please Approve to Mint");
                      console.log(e);
                      return
                  }
              }
    }

    async function connect_WC(){
        const providerwc = new WalletConnectProvider({
              rpc: {
                25:
                  "https://evm-cronos.crypto.org/",
              },
              chainId: 25,
            });
            await providerwc.enable();
            const provider = new ethers.providers.Web3Provider(providerwc);
            return provider;
    }


    async function connect_Cro(){
        const connector = new DeFiWeb3Connector({
                supportedChainIds: [25],
              rpc: {
                1: "https://mainnet.infura.io/v3/INFURA_API_KEY",
                25: "https://evm-cronos.crypto.org/", // cronos mainet
              },
              pollingInterval: 15000,
            });
        await connector.activate();
        const providerwc = await connector.getProvider();
        const provider = new ethers.providers.Web3Provider(providerwc);
        return provider;
    }

    async function connect_Metamask(){
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await window.ethereum.request({method: 'eth_requestAccounts'})
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            return provider;
        }
    }

    async function GetBalances_BOC_WC() {
    try{
            const provider = await connect_WC();
            await connectapp(provider,2);
    }
    catch (e) {
        setconnecterror("Defi Wallet Connect Failed");
    }
  }


    async function GetBalances_BOC_Cro() {
    try{
            const provider = await connect_Cro();
            await connectapp(provider,1);

    }
    catch (e) {
        GetBalances_BOC_WC();
        setconnecterror("Defi Wallet Connect Failed");
    }
  }

    async function GetBalances_BOC() {
    try{
        if (typeof window.ethereum !== 'undefined') {
            const provider = await connect_Metamask();
            await connectapp(provider,0);
        }
    }
    catch (e) {
        console.log(e);
        setconnecterror("Connect Wallet Failed");
    }
  }

  async function mint_NFT_send(){
        const provider = await connect_WC();
        const signer = await provider.getSigner()
        const BOCNFT = new ethers.Contract(BOCNFTAddress, BOCNFTLaunch.abi, signer);
        const account = await provider.getSigner().getAddress();
        const tokenCounter = await BOCNFT.balanceOf(account);

        for (let b = 0; b < tokenCounter ; b++) {
            const owner = await BOCNFT.ownerOf(b);
            if (owner == account){
                const URI = await BOCNFT.tokenURI(b);
                console.log(URI);
            }
          }
    }

  async function allow_NFT_function(provider){
          const signer = provider.getSigner()
          const account = await provider.getSigner().getAddress()
          const BOCNFT = new ethers.Contract(BOCNFTAddress, BOCNFTLaunch.abi, signer);
          const BOCNFTPremier = new ethers.Contract(BOCNFTLaunchPremierAddress, BOCNFTLaunchPremier.abi, signer);
          const BOCNFTExclusive = new ethers.Contract(BOCNFTLaunchExclusiveAddress, BOCNFTLaunchExclusive.abi, signer);
          const dai = new ethers.Contract(DAIAddress, DAI.abi, signer);
          console.log(DAI.abi);



          if(optionsState == '1'){
               try{
                  //await dai.approve(signer.getAddress(), 100000000 );
                  //await dai.approve('0x1087234fe877721F30016ebeD5BEd061397C8851', 100000000 );
                   await dai.approve(BOCNFTAddress, 100000000 );
                  console.log('Approving BOC NFT - 10000')
              }
              catch (e){
                  console.log(e);
                  setconnecterror("NFT Approval Failed");
              }
          }
          else if(optionsState == '2'){
              try{
                  await dai.approve(BOCNFTLaunchPremierAddress, 1000000000 );
                  console.log('Approving BOC NFT - 10000')
              }
              catch (e){
                  console.log(e);
                  setconnecterror("NFT Approval Failed");
              }
          }
          else if(optionsState == '3'){
              try{
                  await dai.approve(BOCNFTLaunchExclusiveAddress, 10000000000 );
                  console.log('Approving BOC NFT - 10000')
              }
              catch (e){
                  console.log(e);
                  setconnecterror("NFT Approval Failed");
              }
          }
  }

    async function allow_NFT_Cro() {

          const provider = await connect_Cro();
          await allow_NFT_function(provider);

   }

    async function allow_NFT() {
        if (connection == '1'){
            const provider = await connect_Cro();
            await allow_NFT_function(provider);
        }
        else if (connection == '2'){
            const provider = await connect_WC();
            await allow_NFT_function(provider);
        }
        else{
            const provider = await connect_Metamask();
            await allow_NFT_function(provider);
        }
   }


    async function mint_NFT_function(provider) {
        const signer = provider.getSigner()
        const account = await provider.getSigner().getAddress()
          const BOCNFT = new ethers.Contract(BOCNFTAddress, BOCNFTLaunch.abi, signer);
          const dai = new ethers.Contract(DAIAddress, DAI.abi, signer);
          console.log(DAI.abi);
          try{
              if(optionsState == '1'){
                  console.log(BOCNFT.tokenCounter())
                  await BOCNFT.create();
                  setmintsuccess("NFT Successfully Minted...")
              }
              else if(optionsState == '2'){
                  mint_NFT_premier(provider);
              }
              else if(optionsState == '3'){
                  mint_NFT_exclusive(provider);
              }
          }
          catch (e){
              console.log(e);
              if(e.data.message.toString().includes("ds-token-insufficient-approval")){
                    setconnecterror("NFT Mint Failed, NFT contract USDC allowance denied");
              }
              else{
                    setconnecterror("NFT Mint Failed, Please ensure USDC Balance exceeds the amount required to Mint");
              }
          }

   }



   async function mint_NFT_function(provider) {
        const signer = provider.getSigner()
        const account = await provider.getSigner().getAddress()
          const multisig = new ethers.Contract(BOCMultisigAddress, BOCMultisig.abi, signer);
          const dai = new ethers.Contract(DAIAddress, DAI.abi, signer);
          console.log(DAI.abi);
          const confirm = multisig.getConfirmationCount(txnum);
          console.log("Confirmations: " + confirm);
          await multisig.confirmTransaction(txnum);
          sleep(1000);
          connectapp(provider,connection);

          // try{
          //     if(optionsState == '1'){
          //         console.log(BOCNFT.tokenCounter())
          //         await BOCNFT.create();
          //         setmintsuccess("NFT Successfully Minted...")
          //     }
          //     else if(optionsState == '2'){
          //         mint_NFT_premier(provider);
          //     }
          //     else if(optionsState == '3'){
          //         mint_NFT_exclusive(provider);
          //     }
          // }
          // catch (e){
          //     console.log(e);
          //     if(e.data.message.toString().includes("ds-token-insufficient-approval")){
          //           setconnecterror("NFT Mint Failed, NFT contract USDC allowance denied");
          //     }
          //     else{
          //           setconnecterror("NFT Mint Failed, Please ensure USDC Balance exceeds the amount required to Mint");
          //     }
          // }

   }

       async function mint_NFT_premier(provider) {
        const signer = provider.getSigner()
          const nftlaunch = new ethers.Contract(BOCNFTLaunchPremierAddress, BOCNFTLaunchPremier.abi, signer);
          const dai = new ethers.Contract(DAIAddress, DAI.abi, signer);
          try{
              await nftlaunch.create();
              setmintsuccess("NFT Successfully Minted...")
          }
          catch (e){
              console.log(e);
              setconnecterror("NFT Mint Failed, Please ensure USDC Balance exceeds the amount required to Mint");
          }
   }

       async function mint_NFT_exclusive(provider) {
          const signer = provider.getSigner()
          const nftlaunch = new ethers.Contract(BOCNFTLaunchExclusiveAddress, BOCNFTLaunchExclusive.abi, signer);
          const dai = new ethers.Contract(DAIAddress, DAI.abi, signer);
          try{
              //await dai.approve(nftlaunch.address, '10000000000000000000000' );
              await nftlaunch.create();
              setmintsuccess("NFT Successfully Minted...")
          }
          catch (e){
              console.log(e);
              setconnecterror("NFT Mint Failed, Please ensure USDC Balance exceeds the amount required to Mint");
          }
   }

    async function mint_NFT() {
       if (connection == '1'){
            const provider = await connect_Cro();
            await mint_NFT_function(provider);
        }
        else if (connection == '2'){
            const provider = await connect_WC();
            await mint_NFT_function(provider);
        }
        else{
            const provider = await connect_Metamask();
            await mint_NFT_function(provider);
        }
   }


       async function load_tx() {
       if (connection == '1'){
            const provider = await connect_Cro();
            await connectapp(provider,connection);
        }
        else if (connection == '2'){
            const provider = await connect_WC();
            await connectapp(provider,connection);
        }
        else{
            const provider = await connect_Metamask();
            await connectapp(provider,connection);
        }
   }

   async function sign_tx() {
       if (connection == '1'){
            const provider = await connect_Cro();
            await mint_NFT_function(provider);
        }
        else if (connection == '2'){
            const provider = await connect_WC();
            await mint_NFT_function(provider);
        }
        else{
            const provider = await connect_Metamask();
            await mint_NFT_function(provider);
        }
   }



   async function create_allow_transaction() {
       const provider = await connect_Metamask();
       const signer = provider.getSigner();
       const account = await provider.getSigner().getAddress();
       const dai = new ethers.Contract(DAIAddress, DAI.abi, signer);
       const multisig = new ethers.Contract(BOCMultisigAddress, BOCMultisig.abi, signer);
       console.log(DAI.abi);


       if (optionsState == '1') {
           try {
               //await dai.approve(signer.getAddress(), 100000000 );
               //await dai.approve('0x1087234fe877721F30016ebeD5BEd061397C8851', 100000000 );
               const data1 = dai.method.approve(account, 22700000000).encodeABI();
               await multisig.submitTransaction(dai.options.address, 0, data1).send();
               await dai.approve(BOCNFTAddress, 100000000);
               console.log('Create TRANS Approve transfer 22.7k')
               sleep(10000);
               const data = dai.method.transfer(account, 22700000000).encodeABI();
               await multisig.submitTransaction(dai.options.address, 0, data).send();
           } catch (e) {
               console.log(e);
               setconnecterror("NFT Approval Failed");
           }
       }
   }



    async function send_with_multisig() {
       if (typeof window.ethereum !== 'undefined') {
          const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner()
          const multisig = new ethers.Contract(BOCMultisigAddress, BOCMultisig.abi, signer);
          const dai = new ethers.Contract(DAIAddress, DAI.abi, signer);
          //let filepath = "./artifacts/img/BOC-T1.svg"
          //let text = fs.readFileSync(filepath)
          //const svg = nfttext.toString('utf-8')
          //await dai.approve(nftlaunch.address, '100000000000000000000' );
          //await nftlaunch.create(svg);
           //const URI = await nftlaunch.tokenURI(1);
              //console.log(URI);
          try{
              await dai.approve(multisig.address, '100000000000000000000' );
              //await multisig.submitTransaction('0x256D2C45E9AEa6A1026f6E88C93E605a81D7F27B',3,'0x12345678');

          }
          catch (e){
              console.log(e);
          }


       }
   }



  return (
    <div className="App" >
        <header className="header">
            <div className="col">
                <div className="content">
                    <p><a href="https://bankofcronos.com/"><img src="https://bankofcronos.com/wp-content/uploads/2021/11/Logo-BoC.png" alt="" width="623" height="107" /></a></p>
					<h1>Bank of Cronos DAO Signing</h1>
					<p>This page is only for Bank of Cronos DAO Delegates</p>
					<br/>
					<p><img src="https://boc.bankofcronos.com/wp-content/uploads/2022/01/boc_tiers_reload.png" alt="" width="600" height="" /></p>
					<br/>
                    <p>Tx {txnum}: {txconfirms} / {txconfirmrequired} Signed</p>
                    <p>Total Transactions: {totaltx} </p>

                    <br/>
                </div>
                    <div id="contact-form">
                        <div>
                           <button onClick={GetBalances_BOC}>Connect Metamask </button>  &nbsp;
                            <button onClick={GetBalances_BOC_Cro}>Connect Defi Wallet </button>
                            <br/>
                            <br/>
                            <span className="required">Transaction: *</span><br/>
                            <input value={txnum} onChange={(val) => settxnum(val.target.value)} />
                        </div>
                        <p id="error">{connecterror}</p>
                        <p id="success">{mintsuccess}</p>
						<br/>
                        <form >
                            <div>
                                <label htmlFor="address">
                                    <span className="required">Address: *</span><br/>
                                    <input value={address}
                                            tabIndex="1" />
                                </label>
                            </div><br/>
                            <div>
                                <label htmlFor="balance">
                                    <span className="required">USDC Balance:</span><br/>
                                    <input  value={daibalance}
                                           tabIndex="2" />
                                </label>
                            </div>

                            <div>

                            </div>
                        </form>
						<br/>
                        <button onClick={sign_tx}>Sign</button>  &nbsp;  <button onClick={load_tx}>Load Transaction</button>
						<br/>
                        


                    </div>





            </div>


        </header>






    </div>



			





  );
}

export default App;